import React, { useContext, useEffect } from 'react'
import LocaleContext from 'context/LocaleProvider'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import GenericPagesLayout from 'components/PagesLayout/GenericPages'
import { withPreview } from 'gatsby-source-prismic'

const Contact = props => {
  const lang = useContext(LocaleContext)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/shell.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '6622300',
          formId: '55b16a57-373e-424d-8d4a-e419ecf0b24c',
          target: '#form-hub-spot',
        })
      }
    })

    lang?.setPageDetails({
      uid: props?.pageContext?.alternativeLanguageUid,
      type: 'page',
    })
  }, [])

  return <GenericPagesLayout query={props?.data?.contact} />
}

Contact.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withPreview(Contact)

export const contactPageQuery = graphql`
  query contacPageQuery($locale: String!) {
    contact: prismicContactPage(lang: { eq: $locale }) {
      alternate_languages {
        uid
      }
      data {
        seo_component {
          document {
            __typename
            ... on PrismicSeo {
              id
              data {
                breadcrumb_title {
                  text
                }
                open_graph_title {
                  text
                }
                open_graph_description {
                  text
                }
                open_graph_image {
                  alt
                  localFile {
                    url
                  }
                }
                seo_title {
                  text
                }
                seo_description {
                  text
                }
              }
            }
          }
        }
        hero_title {
          text
        }
        hero_body {
          text
        }
        form_title {
          text
        }
        form_body {
          text
        }
        success_message {
          text
        }
        first_name_input_label {
          text
        }
        email_input_label {
          text
        }
        email_input_placeholder {
          text
        }
        first_name_input_placeholder {
          text
        }
        last_name_input_label {
          text
        }
        last_name_input_placeholder {
          text
        }
        textarea_placeholder {
          text
        }
        submit_button {
          text
        }
        select_label {
          text
        }
        select_options {
          option {
            text
          }
        }
        body {
          ... on PrismicContactPageBodyContentContainer {
            id
            items {
              contact_title {
                text
              }
              contact_image {
                alt
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              contact_details {
                text
                html
              }
              contact_telephone {
                text
              }
            }
          }
        }
      }
    }
  }
`
